<template>
  <div>
    <a-modal v-model:visible="visible" ok-text="OK" unmountOnClose
             :ok-loading="loading" @before-ok="beforeCreate" @before-cancel="beforeCancel"
             @ok="confirmCreate" @cancel="cancelCreate" :hide-cancel="true">
      <template #title><span class="create-title">Secret Key</span></template>
      <div class="flex-column-start-center" style="padding: 0 4px;">
        <div class="flex-row-start-start">
          <!--          <icon-exclamation-circle :style="{fontSize:'32px'}"/>-->
          <div class="modal-icon-box">
            <icon-exclamation-circle class="modal-icon" />
          </div>
          <div class="modal-icon-text">{{ warningText }}</div>
        </div>
        <div class="flex-row-start-center" style="margin-top: 24px;">
          <span class="text-key">Access Key</span>
          <a-input :style="{width:'372px', paddingRight:'0'}" class="text-input" placeholder="Access Key"
                   v-model="form.accessKey" :disabled="!editable">
            <template #suffix>
              <a-button class="copy-box" @click="copyAk">
                <icon-copy />
              </a-button>
            </template>
          </a-input>
        </div>
        <div class="flex-row-start-center" style="margin-top: 16px;">
          <span class="text-key">Secret Key</span>
          <a-input :style="{width:'372px', paddingRight:'0'}" class="text-input" placeholder="Secret Key"
                   v-model="form.secretKey" :disabled="!editable">
            <template #suffix>
              <a-button class="copy-box" @click="copySk">
                <icon-copy />
              </a-button>
            </template>
          </a-input>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { IconExclamationCircle, IconCopy } from "@arco-design/web-vue/es/icon";
import { copyToClipboard } from "@/utils/clipUtil";
import { queryTenantAccessList } from "@/api/access";

export default {
  components: {
    IconExclamationCircle, IconCopy
  },
  props: {
    envId: { // 'staging'|'prod'
      type: String,
      required: true
    }
  },
  data() {
    return {
      warningText: "This is the ONLY time that the secret keys can be viewed. You cannot recover them later. However, you can create new access keys at any time",
      visible: false,
      editable: true,
      loading: false,
      state: null, // create|view
      form: {
        tenantId: "",
        accessKey: "",
        secretKey: ""
      }
    };
  },
  created() {
    console.log("AccessEditor created! envId=", this.envId);
  },
  methods: {
    openByCreate(record) {
      console.log("openByCreate:", JSON.stringify(record));
      this.state = "create";
      this.assignFields(record, this.form);
      this.visible = true;
      this.editable = true;
      this.loading = false;
    },
    openByView(record) {
      console.log("openByView:", JSON.stringify(record));
      this.state = "view";
      this.assignFields(record, this.form);
      this.visible = true;
      this.editable = false;
      this.loading = false;
    },
    copyAk() {
      console.log("copyAk:");
      copyToClipboard(this.form.accessKey);
      this.$message.info("Access key has been copied to the clipboard");
    },
    copySk() {
      console.log("copySk:");
      copyToClipboard(this.form.secretKey);
      this.$message.info("Secret key has been copied to the clipboard");
    },
    beforeCancel() {
      return !this.loading;
    },
    async beforeCreate(done) {
      if (this.state === "view") {
        done();
        return;
      }
      console.log("beforeCreate:");
      this.editable = false;
      this.loading = true;
      const data = await queryTenantAccessList(this.form.tenantId);
      done();
      this.visible = false;
      this.loading = false;
      // this.editable = true;
      console.log("confirmCreate:", JSON.stringify(data));
      this.$emit("accessCreated", {
        ...data,
        secretVisible: true,
        secretShow: false
      });
    },
    confirmCreate() {
    },
    cancelCreate() {
      console.log("cancelCreate:");
    }
  }
};
</script>
<style lang="scss" scoped>

.text-input {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;
}

.create-title {
  font-size: 16px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 24px;
}

.text-key {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;

  min-width: 76px;
  box-sizing: content-box;
  padding-right: 24px;
}

.copy-box {
  padding: 4px 12px 4px 12px;
}

.modal-icon-box {
  width: 32px;
  height: 32px;
  box-sizing: content-box;
  padding: 0 24px 0 0;
}

.modal-icon {
  font-size: 32px;
  color: #F97846;
}

.modal-icon-text {
  font-size: 16px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 24px;
}
</style>
