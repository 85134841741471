<template>
  <div>
    <!-- 项目名称描述 begin -->
    <div class="title-box">
      <div>
        <span class="title-main" v-if="!projectEditState">{{ projectNameShow }}</span>
        <!--        <a-input class="title-main title-main-input" v-if="projectEditState" v-model="projectName"></a-input>-->
        <a-textarea class="title-main title-main-input" v-if="projectEditState" v-model="projectName"
                    auto-size="true" max-length="50" show-word-limit="true" error="true" />
        <span class="title-sub">(TenantID:{{ tenantIdShow }})</span>
        <a-button class="text-edit" @click="onEditProjectInfo">
          <template #default>Edit</template>
          <template #icon>
            <icon-edit :style="{fontSize:'16px',color:'#333333'}" />
          </template>
        </a-button>
      </div>
      <div class="title-desc" v-if="!projectEditState">{{ projectDescShow }}</div>
      <!--      <a-input class="title-desc title-desc-input" v-if="projectEditState" v-model="projectDesc"></a-input>-->
      <a-textarea class="title-desc title-desc-input" v-if="projectEditState" v-model="projectDesc"
                  auto-size="true" max-length="200" show-word-limit="true" error="true" />
    </div>
    <!-- 项目名称描述 end -->

    <!-- AK/SK列表 begin -->
    <a-tabs v-model="envId" class="my-tabs" :lazy-load="true" :animation="true">
      <a-tab-pane v-for="(envOption) in envOptions" :key="envOption.id" :title="envOption.title">
        <access-list :envId="envOption.id" :tenantId="this.form.tenantId" class="list-box"></access-list>
      </a-tab-pane>
    </a-tabs>
    <!--
    <a-tabs v-model="tabKey" class="my-tabs" :lazy-load="true" :animation="true">
      <a-tab-pane :key="'tab1'" title="Access Keys">
      </a-tab-pane>
      <a-tab-pane :key="'tab2'" title="Webhooks">
      </a-tab-pane>
      <a-tab-pane :key="'tab3'" title="Device Config">
      </a-tab-pane>
      <a-tab-pane :key="'tab3'" title="Notification Cert">
      </a-tab-pane>
    </a-tabs>
    -->
    <!-- AK/SK列表 end -->

    <!-- 编辑项目信息弹窗 begin -->
    <a-modal v-model:visible="createOptions.visible" ok-text="OK" cancel-text="Cancel" unmountOnClose
             :ok-loading="createOptions.loading" @before-ok="beforeCreate" @before-cancel="beforeCancel"
             @ok="confirmCreate" @cancel="cancelCreate">
      <template #title><span class="create-title">Edit Project Info</span></template>
      <div class="flex-column-start-center">
        <a-input :style="{width:'242px'}" class="text-input" placeholder="Tenant ID"
                 v-model="createForm.tenantId" :disabled="!createOptions.editable" allow-clear />
        <a-input :style="{width:'242px',marginTop:'32px'}" class="text-input" placeholder="*Project Name"
                 v-model="createForm.projectName" :disabled="!createOptions.editable" allow-clear />
        <a-textarea :style="{width:'242px',height:'72px',marginTop:'32px',overflow:'scroll'}"
                    class="title-desc title-desc-input" placeholder="Description"
                    v-model="createForm.projectDesc" :disabled="!createOptions.editable"
                    auto-size="true" max-length="200" :error="true" />
      </div>
    </a-modal>
    <!-- 编辑项目信息弹窗 end -->
  </div>
</template>

<script>
import AccessList from "@/views/tenant/access-list";
import { IconEdit } from "@arco-design/web-vue/es/icon";
import { queryTenant } from "@/api/tenant";

export default {
  components: {
    AccessList,
    IconEdit
  },
  data() {
    return {
      form: {
        tenantId: null,
        projectName: "",
        projectDesc: ""
      },
      projectEditState: false,

      tabKey: "tab1",
      envId: "staging",

      createOptions: {
        visible: false,
        editable: true,
        loading: false
      },
      createForm: {
        tenantId: "",
        projectName: "",
        projectDesc: ""
      }
    };
  },
  async created() {
    console.log("tenant-detail created params=", this.$route.params);
    this.form.tenantId = this.$route.params.tenantId;
    this.form = await queryTenant(this.form.tenantId);
  },
  beforeRouteUpdate(to, from) {
    // 在当前路由改变，但是该组件被复用时调用
    // 举例来说，对于一个带有动态参数的路径 `/users/:id`，在 `/users/1` 和 `/users/2` 之间跳转的时候，
    // 由于会渲染同样的 `UserDetails` 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
    // 因为在这种情况发生的时候，组件已经挂载好了，导航守卫可以访问组件实例 `this`
    console.log("beforeRouteUpdate:", to);
  },
  computed: {
    tenantIdShow() {
      return this.showText(this.form.tenantId, "");
    },
    projectNameShow() {
      return this.showText(this.form.projectName, "Project");
    },
    projectDescShow() {
      return this.showText(this.form.projectDesc, "Description");
    }
  },
  methods: {
    beforeCancel() {
      return !this.createOptions.loading;
    },
    async beforeCreate(done) {
      console.log("beforeCreate:");
      this.createOptions.editable = false;
      this.createOptions.loading = true;
      // todo
      await new Promise((resolve, reject) => setTimeout(resolve, 2500));
      done();
    },
    confirmCreate() {
      this.createOptions.visible = false;
      this.createOptions.loading = false;
      this.createOptions.editable = true;
      console.log("confirmCreate:", JSON.stringify(this.createOptions));
      // this.copyProjectFields(this.createOptions, this);
      this.assignFields(this.createForm, this.form);
    },
    copyProjectFields(src, des) {
      des.tenantId = src.tenantId;
      des.projectName = src.projectName;
      des.projectDesc = src.projectDesc;
    },
    cancelCreate() {
      console.log("cancelCreate:");
    },
    showText(str, defaultStr) {
      return (str && str.trim() != "") ? str.trim() : defaultStr;
    },
    onEditProjectInfo() {
      // this.projectEditState = !this.projectEditState;
      // this.copyProjectFields(this, this.createOptions);
      this.assignFields(this.form, this.createForm);
      this.createOptions.visible = true;
    }
  }
};

</script>

<style lang="scss" scoped>

.title-box {
  border-radius: 16px;
  background: #FFFFFF;
  padding: 36px 16px;
}

.title-box * {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.title-main {
  font-size: 24px;
  line-height: 33px;
}

.title-main-input {
  width: 300px;
}

.title-sub {
  font-size: 14px;
  line-height: 20px;

  margin-left: 8px;
}

.title-desc {
  font-size: 14px;
  line-height: 20px;

  margin-top: 16px;
}

.title-desc-input {

}

.my-tabs {
  border-radius: 16px;
  background: #FFFFFF;

  margin-top: 16px;
  padding: 0 21px 16px 21px;
}

.arco-tabs-tab {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4E5969;
  line-height: 22px;
}

.arco-tabs-tab-active {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #F97846;
  line-height: 22px;
  text-shadow: 0px -2px 0px #165DFF;
}

.list-box {
  //padding: 0 21px 16px 21px;
}

.text-edit {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F97846;
  line-height: 20px;

  float: right;
  background-color: white;
}

.text-input {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;
}

.create-title {
  font-size: 16px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 24px;
}

</style>
