import request from "@/utils/request";

export async function queryTenantAccessList(tenantId, envId) {
  const requestConfig = {
    url: `/tenants/${tenantId}/accessList`,
    method: "get",
    params: {
      envId: envId
    }
  };
  const response = await request(requestConfig);
  console.log("queryTenantAccessList response:", response);
  if (response.code === 0) {
    response.data.forEach(access => {
      access.secretShow = false;
    });
    return response.data;
  } else {
    return [];
  }
}

export async function createAccess(tenantId, envId) {
  const response = await request({
    url: `/tenants/${tenantId}/createAccess`,
    method: "post",
    data: {
      envId: envId
    }
  });
  console.log("createNewAccess response:", response);
  if (response.code === 0) {
    return true;
  } else {
    return false;
  }
}

export async function updateAccess(record) {
  const response = await request({
    method: "put",
    url: `/access/${record.accessKey}`,
    data: { active: record.active }
  });
  return (response.code === 0);
}

export async function deleteAccess(accessKey) {
  const response = await request({
    method: "delete",
    url: `/access/${accessKey}`
  });
  return (response.code === 0);
}

