import request from "@/utils/request";

export async function queryTenants(accountId) {
  const requestConfig = {
    url: "/tenants",
    method: "get",
    params: { accountId: accountId }
  };
  const response = await request(requestConfig);
  console.log("queryTenants response:", response);
  if (response.code === 0) {
    return response.data;
  } else {
    return [];
  }
}

export async function deleteTenant(tenantId) {
  console.log("onTenantDelete:", tenantId);
  const requestConfig = {
    url: `/tenants/${tenantId}`,
    method: "delete"
  };
  const response = await request(requestConfig);
  console.log("updateTenant response:", response);
  if (response.code === 0) {
    return true;
  } else {
    return false;
  }
}

export async function queryTenant(tenantId) {
  const requestConfig = {
    url: `/tenants/${tenantId}`,
    method: "get"
  };
  const response = await request(requestConfig);
  console.log("queryTenant response:", response);
  if (response.code === 0) {
    return response.data;
  } else {
    return null;
  }
}

export async function createTenant(form) {
  const requestConfig = {
    url: "/tenants",
    method: "post",
    data: form
  };
  const response = await request(requestConfig);
  console.log("createTenant response:", response);
  if (response.code === 0) {
    return response.data;
  } else {
    return null;
  }
}

export async function updateTenant(form) {
  const requestConfig = {
    url: `/tenants/${form.tenantId}`,
    method: "put",
    data: form
  };
  const response = await request(requestConfig);
  console.log("updateTenant response:", response);
  if (response.code === 0) {
    return response.data;
  } else {
    return null;
  }
}
