<template>
  <div>
    <div class="flex-row-start-between">
      <a-button class="btn-create-access" @click="onCreateNewKey"
                :disabled="this.listOptions.loading">Create New key
      </a-button>
      <a-button class="btn-create-access" @click="onRefreshList"
                :disabled="this.listOptions.loading">Refresh List
      </a-button>
    </div>
    <a-table
      :data="listOptions.noData ? [] : listData"
      :bordered="listOptions.border"
      :hoverable="listOptions.hover"
      :stripe="listOptions.stripe"
      :loading="listOptions.loading"
      :show-header="listOptions.tableHeader"
      :row-selection="listOptions.checkbox ? listSelection : undefined"
    >
      <template #columns>
        <a-table-column>
          <template #title><span class="text-head">Access Key</span></template>
          <template #cell="{ record }"><span class="text-grid">{{ record.accessKey }}</span></template>
        </a-table-column>
        <a-table-column>
          <template #title><span class="text-head">Secret Key</span></template>
          <template #cell="{ record }">
            <template v-if="record.secretVisible">
              <a-button v-if="!record.secretShow" @click="onAccessSecretShow(record)"
                        type="text" class="btn-show-secret">Show
              </a-button>
              <span v-else class="text-grid">{{ record.secretKey }}</span>
            </template>
            <span v-else class="text-grid">invisible</span>
          </template>
        </a-table-column>
        <a-table-column>
          <template #title><span class="text-head">Create Time</span></template>
          <template #cell="{ record }"><span class="text-grid">{{ record.cdate }}</span></template>
        </a-table-column>
        <a-table-column>
          <template #title><span class="text-head">Active</span></template>
          <template #cell="{ record }">
            <a-switch v-model="record.active" class="my-switch" @change="onAccessActiveChange(record)">
              <template #checked><span class="text-switch">ON</span></template>
              <template #unchecked><span class="text-switch">OFF</span></template>
            </a-switch>
          </template>
        </a-table-column>
        <a-table-column>
          <template #title><span class="text-head">Delete</span></template>
          <template #cell="{ record }">
            <a-link style="padding: 6px;" @click="onAccessDelete(record)">
              <icon-delete :style="{fontSize:'16px',color:'#000000'}" />
            </a-link>
          </template>
        </a-table-column>
      </template>
    </a-table>

    <!-- 编辑项目信息弹窗 begin -->
    <access-editor @access-created="onAccessCreated" :envId="envId" ref="accessEditor"></access-editor>
    <!-- 编辑项目信息弹窗 end -->
  </div>
</template>
<script>
import { IconDelete } from "@arco-design/web-vue/es/icon";
import AccessEditor from "@/views/tenant/access-edit";
import { createAccess, deleteAccess, queryTenantAccessList, updateAccess } from "@/api/access";

export default {
  components: {
    IconDelete,
    AccessEditor
  },
  props: {
    envId: { // 'staging'|'prod'
      type: String,
      required: true
    },
    tenantId: { // 'staging'|'prod'
      type: String,
      required: true
    }
  },
  data() {
    return {
      listOptions: {
        border: true,
        borderCell: false,
        hover: true,
        stripe: false,
        checkbox: false,
        loading: true,
        tableHeader: true,
        noData: false
      },
      listSelection: {
        type: "checkbox",
        showCheckedAll: true
      },
      listData: []
      // createAccessVisible: false
    };
  },
  async created() {
    console.log("AccessList created! envId=", this.envId, "tenantId=", this.tenantId);
    this.listData = await queryTenantAccessList(this.tenantId, this.envId);
    this.listOptions.loading = false;
    // this.listData = buildListDataExample();
  },
  watch: {
    // listData(newVal, oldVal) {
    //   console.log("watch listData");
    //   this.listOptions.noData = newVal && newVal.length > 0;
    // }
  },
  methods: {
    async onRefreshList() {
      this.listOptions.loading = true;
      this.listData = await queryTenantAccessList(this.tenantId, this.envId);
      this.listOptions.loading = false;
    },
    onAccessCreated(newAccess) {
      console.log("onAccessCreated:", JSON.stringify(newAccess));
      this.listData.push(newAccess);
    },
    async onCreateNewKey() {
      // console.log("onCreateNewKey:");
      // this.createAccessVisible = true;
      // this.$refs.accessEditor.openByCreate({ tenantId: this.tenantId });
      const createSucc = await createAccess(this.tenantId, this.envId);
      if (createSucc) {
        this.onRefreshList();
      }
    },
    onAccessSecretShow(record) {
      // record.secretShow = true;
      // console.log("onAccessSecretShow:", record.accessKey, record.secretShow);
      this.$refs.accessEditor.openByView(record);
    },
    async onAccessActiveChange(record) {
      console.log("onAccessActiveChange:", record.accessKey, record.active);
      const updated = await updateAccess(record);
      if (!updated) {
        this.onRefreshList();
      }
    },
    async onAccessDelete(record) {
      const deleted = await deleteAccess(record.accessKey);
      if (deleted) {
        this.onRefreshList();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-show-secret {
  font-size: 14px;
  font-family: Helvetica;
  color: #F97846;
  line-height: 22px;
}

.text-grid {
  font-size: 14px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 22px;
}

.text-head {
  font-size: 14px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 22px;
  text-shadow: 1px 0px 0px #F2F3F5;
}

.my-switch {
  background: #F97846;
}

.text-switch {
  font-size: 12px;
  font-family: Helvetica;
  color: #FFFFFF;
  line-height: 20px;
}

.btn-create-access {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;

  margin-bottom: 16px;
}

</style>
